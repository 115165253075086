import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, CircularProgress, Snackbar
} from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { AccountDetails, Password } from './components';
import { useQuery } from "react-query";
import axios from 'axios';
import { withHttpErrorHandler } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const getUser = async (userId) => {
  const { data } = await axios.get("/secure/user/" + userId);
  return data;
};

const Account = () => {
  const classes = useStyles();
  const userId = useSelector(state => state.auth.userId);
  const [user, setUser] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const { status, data, isFetching } = useQuery([userId], getUser, { retry: false, refetchOnWindowFocus: false });

  useEffect(() => {
    if (data !== undefined) {
      setUser(data);
    }
  }, [data]);

  const handleUpdatePassword = async ({ password }) => {
    const payload = {
      email: user.email,
      password: password
    };

    try {
      await axios.put("secure/users/password", payload);
      setSuccessMsg('Update password successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateProfile = async (user) => {
    const editedUser = {
      user_id: user.user_id,
      user_name: user.user_name,
      user_addr: user.user_addr,
      user_phone: user.user_phone,
      user_alter_phone: user.user_alter_phone,
      gender: user.gender,
      birthday: user.birthday
    };

    try {
      await axios.put("secure/users", editedUser);
      setSuccessMsg('Update profile successfully');
    } catch (error) {
      console.error(error);
    }
  };

  const handleSuccessMsgClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMsg(null);
  };

  if (isFetching || status === 'loading') {
    return <CircularProgress />
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item lg={8} md={8} xl={8} xs={12}>
          {user ? <AccountDetails user={user} onUpdateProfile={handleUpdateProfile} /> : null}
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12}>
          {user ? <Password onUpdatePassword={handleUpdatePassword} /> : null}
        </Grid>
        <Snackbar open={Boolean(successMsg)} autoHideDuration={3000} onClose={handleSuccessMsgClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <MuiAlert elevation={6} variant="filled" onClose={handleSuccessMsgClose} severity="success">
            {successMsg}
          </MuiAlert>
        </Snackbar>
      </Grid>
    </div>
  );
};

export default withHttpErrorHandler(Account);