import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import schema from '../../../../common/storeValidation';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  Typography, List, ListItem, ListSubheader, Checkbox, FormControlLabel,
  FormControl, InputLabel, Select, MenuItem, FormHelperText, IconButton, Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  availableTimesBlock: {
    borderRadius: '4px',
    border: '1px solid #ccc',
    padding: '8px'
  },
  coordInfo: {
    width: '100%'
  },
  availableDay: {
    lineHeight: '24px',
    fontSize: '0.85rem'
  },
}));

const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const times = ['Mañana', 'Tarde', 'Noche'];

const StoreDialog = props => {
  const { open, store, onClose, onAddStore, onUpdateStore, convertStrToAvailableTimesPerDay } = props;

  const classes = useStyles();

  const [isCreateStore] = useState(!store);
  const [errorMsg, setErrorMsg] = useState(null);
  const [departamentos, setDepartamentos] = useState([]);
  const [distritos, setDistritos] = useState([]);
  const [barrios, setBarrios] = useState([]);
  const [schemaWhitelist, setSchemaWhitelist] = useState(
    {
      store_name: true,
      store_phone: true,
      store_note: true,
      codigo_departamento: true,
      codigo_distrito: true,
      codigo_barrio: false,
      address: true,
      latitude: true,
      longitude: true
    }
  );

  const [availableTimesPerDay, setAvailableTimesPerDay] = useState(
    isCreateStore ? [...days.keys()].map((dayIndex) => { return dayIndex === 0 ? [...Array(times.length)].fill(false) : [...Array(times.length)].fill(true) })
      : convertStrToAvailableTimesPerDay(store.available_time)
  );

  const [neverTouched, setNeverTouched] = useState(true);

  const [formState, setFormState] = useState({
    isValid: false,
    values: isCreateStore ? {} : store,
    touched: {},
    errors: {}
  });

  useEffect(() => {
    fetchDepartamentos();
  }, []);

  useEffect(() => {
    if (!isCreateStore) {
      if (store.codigo_departamento) {
        fetchDistritos(store.codigo_departamento);
      }
      if (store.codigo_departamento && store.codigo_distrito) {
        fetchBarrios(store.codigo_departamento, store.codigo_distrito);
      }
    }
  }, [isCreateStore, store]);

  useEffect(() => {
    const errors = validate(formState.values, validate.cleanAttributes(schema, schemaWhitelist));
    setFormState(
      produce(draft => {
        draft.isValid = errors ? false : true;
        draft.errors = errors || {};
      })
    );
  }, [formState.values, schemaWhitelist]);

  const fetchDepartamentos = async () => {
    try {
      const { data } = await axios.get('/departamentos');
      setDepartamentos(data);
    } catch (error) {
      console.error(error);
    }
  }

  const fetchDistritos = async (codigo_departamento) => {
    try {
      const { data } = await axios.get('/distritos/cod_departamento/' + codigo_departamento);
      setDistritos(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBarrios = async (codigo_departamento, codigo_distrito) => {
    try {
      const { data } = await axios.get('/barrios/cod_departamento/' + codigo_departamento + '/cod_distrito/' + codigo_distrito);
      setBarrios(data);
      setSchemaWhitelist(
        produce(draft => {
          draft.codigo_barrio = data.length > 0;
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState(
      produce(draft => {
        draft.values[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        draft.touched[event.target.name] = true;
      })
    );
    setNeverTouched(false);
  };

  const handleDepartamentoChange = event => {
    handleChange(event);

    if (event.target.value) {
      fetchDistritos(event.target.value);
    } else {
      setDistritos([]);
    }

    setBarrios([]);
    setSchemaWhitelist(
      produce(draft => {
        draft.codigo_barrio = false;
      })
    );
    setFormState(
      produce(draft => {
        draft.values.codigo_distrito = '';
        draft.values.codigo_barrio = '';
      })
    );
  }

  const handleDistritoChange = event => {
    handleChange(event);

    if (event.target.value) {
      fetchBarrios(formState.values.codigo_departamento, event.target.value);
    } else {
      setBarrios([]);
      setSchemaWhitelist(
        produce(draft => {
          draft.codigo_barrio = false;
        })
      );
    }

    setFormState(
      produce(draft => {
        draft.values.codigo_barrio = '';
      })
    );
  }

  let hasValidationError = null;
  if (isCreateStore) {
    hasValidationError = field => formState.touched[field] && formState.errors[field] ? true : false;
  } else {
    hasValidationError = field => formState.errors[field] ? true : false;
  }

  const handleAvailableTimesChange = (dayIndex, timeIndex) => {
    setAvailableTimesPerDay(
      produce(draft => {
        draft[dayIndex][timeIndex] = !draft[dayIndex][timeIndex];
      })
    );
    setNeverTouched(false);
  }

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setFormState(
            produce(draft => {
              draft.values['latitude'] = position.coords.latitude.toString().substring(0, schema.latitude.length.maximum);
              draft.touched['latitude'] = true;
              draft.values['longitude'] = position.coords.longitude.toString().substring(0, schema.longitude.length.maximum);
              draft.touched['longitude'] = true;
            })
          );
          setNeverTouched(false);
        },
        showGetLocationError,
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 5 * 60 * 1000 }
      );
    } else {
      setErrorMsg("Geolocation is not supported by this browser");
    }
  }

  const showGetLocationError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setErrorMsg("Permission denied for the request of Geolocation");
        break;
      case error.POSITION_UNAVAILABLE:
        setErrorMsg("Geolocation information is unavailable");
        break;
      case error.TIMEOUT:
        setErrorMsg("The request to get Geolocation timed out");
        break;
      case error.UNKNOWN_ERROR:
      default:
        setErrorMsg("An unknown error occurred while getting Geolocation information");
        break;
    }
  }

  const handleErrorMsgClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(null);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} disableBackdropClick={true} fullWidth={true} maxWidth="sm">
        <DialogTitle id="form-dialog-title">{isCreateStore ? 'Create Store' : 'Edit Store'}</DialogTitle>
        <DialogContent>
          <form autoComplete="off">
            <Grid container spacing={2} className={classes.content}>
              <Grid item xs={12}>
                <TextField
                  error={hasValidationError('store_name')}
                  required
                  fullWidth
                  inputProps={{ maxLength: schema.store_name.length.maximum }}
                  helperText={
                    hasValidationError('store_name') ? formState.errors.store_name[0] : null
                  }
                  label="Store Name"
                  name="store_name"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.store_name || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={hasValidationError('store_phone')}
                  required
                  fullWidth
                  inputProps={{ maxLength: schema.store_phone.length.maximum }}
                  helperText={
                    hasValidationError('store_phone') ? formState.errors.store_phone[0] : null
                  }
                  label="Phone"
                  name="store_phone"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.store_phone || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                <FormControl required variant="outlined" fullWidth error={hasValidationError('codigo_departamento')}>
                  <InputLabel id="departamento-label">Departamento</InputLabel>
                  <Select labelId="departamento-label"
                    id="codigo_departamento"
                    name="codigo_departamento"
                    label="Departamento"
                    onChange={handleDepartamentoChange}
                    type="select"
                    value={departamentos.length > 0 ? formState.values.codigo_departamento || '' : ''}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {
                      departamentos.map(item => {
                        return (
                          <MenuItem key={`departamento-${item.codigo_departamento}`} value={item.codigo_departamento}>{item.nombre_departamento}</MenuItem>
                        );
                      })
                    }
                  </Select>
                  <FormHelperText>{hasValidationError('codigo_departamento') ? formState.errors.codigo_departamento[0] : null}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={12}>
                <FormControl required variant="outlined" fullWidth error={hasValidationError('codigo_distrito')}>
                  <InputLabel id="distrito-label">Distrito</InputLabel>
                  <Select labelId="distrito-label"
                    id="codigo_distrito"
                    name="codigo_distrito"
                    label="Distrito"
                    onChange={handleDistritoChange}
                    type="select"
                    value={distritos.length > 0 ? formState.values.codigo_distrito || '' : ''}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {
                      distritos.map(item => {
                        return (
                          <MenuItem key={`distrito-${item.codigo_distrito}`} value={item.codigo_distrito}>{item.nombre_distrito}</MenuItem>
                        );
                      })
                    }
                  </Select>
                  <FormHelperText>{hasValidationError('codigo_distrito') ? formState.errors.codigo_distrito[0] : null}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={12}>
                <FormControl required={schemaWhitelist.codigo_barrio} variant="outlined" fullWidth error={hasValidationError('codigo_barrio')}>
                  <InputLabel id="barrio-label">Barrio</InputLabel>
                  <Select labelId="barrio-label"
                    id="codigo_barrio"
                    name="codigo_barrio"
                    label="Barrio"
                    onChange={handleChange}
                    type="select"
                    value={barrios.length > 0 ? formState.values.codigo_barrio || '' : ''}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {
                      barrios.map(item => {
                        return (
                          <MenuItem key={`barrio-${item.codigo_barrio}`} value={item.codigo_barrio}>{item.nombre_barrio}</MenuItem>
                        );
                      })
                    }
                  </Select>
                  <FormHelperText>{hasValidationError('codigo_barrio') ? formState.errors.codigo_barrio[0] : null}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={hasValidationError('address')}
                  required
                  fullWidth
                  inputProps={{ maxLength: schema.address.length.maximum }}
                  helperText={
                    hasValidationError('address') ? formState.errors.address[0] : null
                  }
                  label="Address"
                  name="address"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.address || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} container wrap="nowrap">
                <Alert variant="outlined" severity="info" className={classes.coordInfo}>
                  <a href="https://support.google.com/maps/answer/18539?co=GENIE.Platform%3DDesktop&hl=es-419" target="_blank" rel="noreferrer noopener">
                    Cómo buscar o ingresar coordenadas de latitud y longitud
                </a>
                </Alert>
                <IconButton onClick={handleGetLocation}>
                  <GpsFixedIcon />
                </IconButton>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  error={hasValidationError('latitude')}
                  required
                  fullWidth
                  inputProps={{ maxLength: schema.latitude.length.maximum }}
                  helperText={
                    hasValidationError('latitude') ? formState.errors.latitude[0] : null
                  }
                  label="Latitude"
                  name="latitude"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.latitude || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  error={hasValidationError('longitude')}
                  required
                  fullWidth
                  inputProps={{ maxLength: schema.longitude.length.maximum }}
                  helperText={
                    hasValidationError('longitude') ? formState.errors.longitude[0] : null
                  }
                  label="Longitude"
                  name="longitude"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.longitude || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={hasValidationError('store_note')}
                  fullWidth
                  inputProps={{ maxLength: schema.store_note.length.maximum }}
                  helperText={
                    hasValidationError('store_note') ? formState.errors.store_note[0] : null
                  }
                  multiline={true}
                  rows={4}
                  rowsMax={4}
                  label="Note"
                  name="store_note"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.store_note || ''}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Grid className={classes.availableTimesBlock}>
                  <Typography variant="h6">Available Times</Typography>
                  <List component="div" dense={true}>
                    {[...days.keys()].map(dayIndex => {
                      return (
                        <ul key={`availableDay-${dayIndex}`}>
                          <ListSubheader disableGutters disableSticky classes={{ root: classes.availableDay }}>{days[dayIndex]}</ListSubheader>
                          <ListItem dense disableGutters>
                            {[...times.keys()].map(timeIndex => {
                              return (
                                <FormControlLabel
                                  key={`availableTimes-${dayIndex}-${timeIndex}`}
                                  control={
                                    <Checkbox
                                      checked={availableTimesPerDay[dayIndex][timeIndex]}
                                      onChange={() => { handleAvailableTimesChange(dayIndex, timeIndex) }}
                                      color="primary"
                                    />
                                  }
                                  label={times[timeIndex]}
                                />
                              );
                            })}
                          </ListItem>
                        </ul>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
        </Button>
          <Button
            onClick={() => { isCreateStore ? onAddStore(formState.values, availableTimesPerDay) : onUpdateStore(formState.values, availableTimesPerDay); }}
            color="primary"
            variant="contained"
            disabled={neverTouched || !formState.isValid}
          >
            OK
        </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={Boolean(errorMsg)} autoHideDuration={3000} onClose={handleErrorMsgClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert elevation={6} variant="filled" onClose={handleErrorMsgClose} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

StoreDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  store: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onAddStore: PropTypes.func.isRequired,
  onUpdateStore: PropTypes.func.isRequired,
  convertStrToAvailableTimesPerDay: PropTypes.func.isRequired
};

export default StoreDialog;