import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import { UsersTable, EditUserDialog, ActivateUserDialog, ResetPasswordDialog } from './components';
import { useQuery } from "react-query";
import axios from 'axios';
import { withHttpErrorHandler } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const getUsers = async () => {
  const { data } = await axios.get(
    "/secure/users"
  );
  return data;
};

const UserList = () => {
  const classes = useStyles();
  const [successMsg, setSuccessMsg] = useState(null);
  const [editUserOpen, setEditUserOpen] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const [resetPasswordUser, setResetPasswordUser] = useState(null);
  const [activateUserOpen, setActivateUserOpen] = useState(false);
  const [activatedUser, setActivatedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const { status, data, isFetching } = useQuery("users", getUsers, { retry: false, refetchOnWindowFocus: false });

  useEffect(() => {
    if (data !== undefined) {
      setUsers(data);
    }
  }, [data]);

  const handleUserDelete = async (user) => {
    try {
      await axios.delete("/secure/user/" + user.user_id);
      setUsers(users.filter(item => item.user_id !== user.user_id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleToggleUserActivation = (user) => {
    setActivatedUser(user);
    setActivateUserOpen(true);
  }

  const handleResetPassword = (user) => {
    setResetPasswordUser(user);
    setResetPasswordOpen(true);
  }

  const handleResetPasswordCancel = () => {
    setResetPasswordOpen(false);
    setResetPasswordUser(null);
  };

  const handleResetPasswordOk = async (user) => {
    const payload = {
      user_id: user.user_id,
    };

    try {
      await axios.put("secure/users/resetpass", payload);
      setResetPasswordOpen(false);
      setResetPasswordUser(null);
      setSuccessMsg('Reset password successfully');
    } catch (error) {
      console.error(error);
    }
  }

  const handleUserEdit = async (user) => {
    try {
      const { data } = await axios.get("/secure/user/" + user.user_id);
      setEditedUser(data);
      setEditUserOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditUserCancel = () => {
    setEditUserOpen(false);
    setEditedUser(null);
  };

  const handleEditUserOk = async (user) => {
    const editedUser = {
      user_id: user.user_id,
      user_name: user.user_name,
      user_addr: user.user_addr,
      user_phone: user.user_phone,
      user_alter_phone: user.user_alter_phone,
      gender: user.gender,
      birthday: user.birthday
    };

    try {
      await axios.put("secure/users", editedUser);
      const userIndex = users.findIndex(item => item.user_id === editedUser.user_id);
      setUsers(
        users.map((item, index) => {
          if (index !== userIndex) {
            return item
          }
          return {
            ...item,
            user_name: editedUser.user_name
          }
        })
      );
      setEditUserOpen(false);
      setEditedUser(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleActivateUserCancel = () => {
    setActivateUserOpen(false);
    setActivatedUser(null);
  };

  const handleActivateUserOk = async (user, note) => {
    try {
      // Deactivate the user if he currently is activated.
      const toDeactivate = user.is_activate === 't';
      if (toDeactivate) {
        await axios.put("/secure/users/deactivate", { user_id: user.user_id, deactivate_examiner_note: note });
      } else {
        await axios.put("/secure/users/activate", { user_id: user.user_id, activate_examiner_note: note });
      }
      const userIndex = users.findIndex(item => item.user_id === user.user_id);
      setUsers(
        users.map((item, index) => {
          if (index !== userIndex) {
            return item
          }
          return {
            ...item,
            is_activate: toDeactivate ? 'f' : 't'
          }
        })
      );
      setActivateUserOpen(false);
      setActivatedUser(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSuccessMsgClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMsg(null);
  };

  if (isFetching || status === 'loading') {
    return <CircularProgress />
  }

  let editUserDialog = null;
  if (editUserOpen) {
    editUserDialog = <EditUserDialog user={editedUser} open={editUserOpen} onClose={handleEditUserCancel} onOk={handleEditUserOk} />;
  }

  let resetPasswordDialog = null;
  if (resetPasswordOpen) {
    resetPasswordDialog = <ResetPasswordDialog user={resetPasswordUser} open={resetPasswordOpen} onClose={handleResetPasswordCancel} onOk={handleResetPasswordOk} />;
  }

  let activateUserDialog = null;
  if (activateUserOpen) {
    activateUserDialog = <ActivateUserDialog user={activatedUser} open={activateUserOpen} onClose={handleActivateUserCancel} onOk={handleActivateUserOk} />;
  }

  return (
    <div className={classes.root}>
      <div>
        <UsersTable
          users={users}
          onUserEdit={handleUserEdit}
          onResetPassword={handleResetPassword}
          onUserDelete={handleUserDelete}
          onToggleUserActivation={handleToggleUserActivation}
        />
        {editUserDialog}
        {resetPasswordDialog}
        {activateUserDialog}
      </div>
      <Snackbar open={Boolean(successMsg)} autoHideDuration={3000} onClose={handleSuccessMsgClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSuccessMsgClose} severity="success">
          {successMsg}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default withHttpErrorHandler(UserList);