import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiDialog from './MuiDialog';
import MuiCardHeader from './MuiCardHeader';
import MuiCardContent from './MuiCardContent';
import MuiCardActions from './MuiCardActions';

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiTypography,
  MuiDialog,
  MuiCardHeader,
  MuiCardContent,
  MuiCardActions
};
