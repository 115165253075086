import axios from 'axios';

import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (accessToken, refreshToken, accessExpirationDate, refreshExpirationDate, userId, userName, roleId, roleName) => {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
  return {
    type: actionTypes.AUTH_SUCCESS,
    accessToken: accessToken,
    refreshToken: refreshToken,
    accessExpirationDate: accessExpirationDate,
    refreshExpirationDate: refreshExpirationDate,
    userId: userId,
    userName: userName,
    roleId: roleId,
    roleName: roleName
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const authLogout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const authRefreshToken = (accessToken, refreshToken, accessExpirationDate, refreshExpirationDate) => {
  return {
    type: actionTypes.AUTH_REFRESH_TOKEN,
    accessToken: accessToken,
    refreshToken: refreshToken,
    accessExpirationDate: accessExpirationDate,
    refreshExpirationDate: refreshExpirationDate
  };
};

export const logout = () => {
  return dispatch => {
    if (localStorage.getItem('accessToken')) {
      // No need to change Authorization header. It should already be accessToken
      axios.delete('/login')
        .catch(err => {
          console.warn(err);
        });
    }

    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      // Change Authorization header as refreshToken
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken;
      axios.delete('/refresh')
        .catch(err => {
          console.warn(err);
        });
    }

    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessExpirationDate');
    localStorage.removeItem('refreshExpirationDate');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('roleId');
    localStorage.removeItem('roleName');

    dispatch(authLogout());
  }
};

export const auth = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password
    };

    axios.post('/login', authData)
      .then(response => {
        const now = Date.now();
        const accessExpirationDate = new Date(now + response.data.access_token_expires_sec * 1000);
        const refreshExpirationDate = new Date(now + response.data.refresh_token_expires_sec * 1000);

        localStorage.setItem('accessToken', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        localStorage.setItem('accessExpirationDate', accessExpirationDate);
        localStorage.setItem('refreshExpirationDate', refreshExpirationDate);
        localStorage.setItem('userId', response.data.user_id);
        localStorage.setItem('userName', response.data.user_name);
        localStorage.setItem('roleId', response.data.role_id);
        localStorage.setItem('roleName', response.data.role_name);
        dispatch(authSuccess(
          response.data.access_token,
          response.data.refresh_token,
          accessExpirationDate,
          refreshExpirationDate,
          response.data.user_id,
          response.data.user_name,
          response.data.role_name)
        );
      })
      .catch(err => {
        dispatch(authFail(err.message));
      });
  };
};

export const authAutoSignin = () => {
  return dispatch => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
      dispatch(logout());
    } else {
      const now = new Date()
      const accessExpirationDate = new Date(localStorage.getItem('accessExpirationDate'));
      const refreshExpirationDate = new Date(localStorage.getItem('refreshExpirationDate'));
      if (accessExpirationDate <= now && refreshExpirationDate <= now) {
        dispatch(logout());
      } else {
        const refreshToken = localStorage.getItem('refreshToken');
        const userId = localStorage.getItem('userId');
        const userName = localStorage.getItem('userName');
        const roleId = localStorage.getItem('roleId');
        const roleName = localStorage.getItem('roleName');
        dispatch(authSuccess(accessToken, refreshToken, accessExpirationDate, refreshExpirationDate, userId, userName, roleId, roleName));
      }
    }
  };
};