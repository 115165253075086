import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import produce from 'immer';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, TextField
} from '@material-ui/core';

const schema = {
  note: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 800
    }
  }
}

const ActivateUserDialog = props => {
  const { user, open, onClose, onOk } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(
      produce(draft => {
        draft.isValid = errors ? false : true;
        draft.errors = errors || {};
      })
    );
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(
      produce(draft => {
        draft.values[event.target.name] = event.target.value;
        draft.touched[event.target.name] = true;
      })
    );
  };

  const hasValidationError = field => formState.touched[field] && formState.errors[field] ? true : false;

  const isActivated = user.is_activate === 't';

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">{isActivated ? 'Deactivate' : 'Activate'} User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure to {isActivated ? 'deactivate' : 'activate'} <b>{user.user_name}</b>?
        </DialogContentText>
        <TextField
          fullWidth
          required
          autoComplete="off"
          inputProps={{ maxLength: schema.note.length.maximum }}
          helperText={
            hasValidationError('note') ? formState.errors.note[0] : null
          }
          multiline={true}
          rows={5}
          rowsMax={5}
          onChange={handleChange}
          label="Note"
          name="note"
          type="text"
          value={formState.values.note || ''}
          variant="outlined"
          error={hasValidationError('note')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { onOk(user, formState.values.note); }} color="primary" variant="contained" disabled={!formState.isValid}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ActivateUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default ActivateUserDialog;