import { useState } from 'react';
import axios from 'axios';

export default useInterceptors => {
  const [errorMsg, setErrorMsg] = useState(null);

  axios.interceptors.request.use(request => {
    return request;
  }, error => {
    setErrorMsg(error.message);
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    setErrorMsg(error.message);
    return Promise.reject(error);
  });

  const clearErrorHandler = () => {
    setErrorMsg(null);
  };

  return [errorMsg, clearErrorHandler];
}