import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, CircularProgress, Badge } from '@material-ui/core';
import StoreIcon from '@material-ui/icons/Store';
import { StoreCard, DeleteStoreDialog, StoreDialog } from './components';
import { useQuery } from "react-query";
import axios from 'axios';
import { withHttpErrorHandler } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  flexContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  }
}));

const getStores = async (ownerId) => {
  if (ownerId) {
    const { data } = await axios.get("/secure/stores/user/" + ownerId);
    return data;
  } else {
    const { data } = await axios.get("/secure/stores");
    return data;
  }
};

function useUrlQuery() {
  return new URLSearchParams(useLocation().search);
}

const StoreCardList = () => {
  const classes = useStyles();

  const isAdmin = useSelector(state => state.auth.roleId === '01');
  const userId = useSelector(state => state.auth.userId);
  const [deleteStoreOpen, setDeleteStoreOpen] = useState(false);
  const [deletedStore, setDeletedStore] = useState(null);
  const [editingStore, setEditingStore] = useState(null);
  const [storeDialogOpen, setStoreDialogOpen] = useState(false);
  const urlQuery = useUrlQuery();
  const owner = urlQuery.get("owner");
  const history = useHistory();
  const [stores, setStores] = useState([]);
  const { status, data, isFetching, refetch } = useQuery([owner], getStores, { retry: false, refetchOnWindowFocus: false });

  useEffect(() => {
    if (data !== undefined) {
      setStores(data);
    }
  }, [data]);

  const handleAddStoreClick = () => {
    setEditingStore(null);
    setStoreDialogOpen(true);
  };

  const handleEditStoreClick = async (store) => {
    try {
      const { data } = await axios.get("secure/store/" + store.store_id);
      setEditingStore(data);
      setStoreDialogOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStoreDialogCancel = () => {
    setStoreDialogOpen(false);
    setEditingStore(null);
  };

  const convertAvailableTimesPerDayToStr = (availableTimesPerDay) => {
    let available_time = ''
    for (let timeIndex = 0; timeIndex < availableTimesPerDay[0].length; timeIndex++) {
      for (let dayIndex = 0; dayIndex < availableTimesPerDay.length; dayIndex++) {
        available_time += availableTimesPerDay[dayIndex][timeIndex] ? 'Y' : 'N';
      }
    }
    return available_time;
  }

  const convertStrToAvailableTimesPerDay = (str) => {
    const timesPerDay = Array(7).fill().map(() => Array(3).fill(false));

    for (let timeIndex = 0; timeIndex < timesPerDay[0].length; timeIndex++) {
      for (let dayIndex = 0; dayIndex < timesPerDay.length; dayIndex++) {
        const c = str.charAt(timesPerDay.length * timeIndex + dayIndex);
        if (c === 'Y' || c === 'y') {
          timesPerDay[dayIndex][timeIndex] = true;
        }
      }
    }
    return timesPerDay;
  }

  const handleAddStoreOk = async (store, availableTimesPerDay) => {
    const available_time = convertAvailableTimesPerDayToStr(availableTimesPerDay);
    const newStore = {
      ...store,
      owner_id: isAdmin ? owner : userId,
      available_time: available_time
    };

    try {
      await axios.post("secure/stores", newStore);
      setStoreDialogOpen(false);
      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateStoreOk = async (store, availableTimesPerDay) => {
    const available_time = convertAvailableTimesPerDayToStr(availableTimesPerDay);
    const updatedStore = {
      owner_id: isAdmin ? owner : userId,
      store_id: store.store_id,
      store_name: store.store_name,
      store_phone: store.store_phone,
      store_note: store.store_note ? store.store_note : '',
      codigo_departamento: store.codigo_departamento,
      codigo_distrito: store.codigo_distrito,
      codigo_barrio: store.codigo_barrio,
      address: store.address,
      latitude: store.latitude,
      longitude: store.longitude,
      available_time: available_time
    };

    try {
      await axios.put("secure/stores", updatedStore);
      const storeIndex = stores.findIndex(item => item.store_id === updatedStore.store_id);
      setStores(
        stores.map((item, index) => {
          if (index !== storeIndex) {
            return item
          }
          return {
            ...item,
            store_name: updatedStore.store_name,
            address: updatedStore.address,
            store_phone: updatedStore.store_phone,
            latitude: updatedStore.latitude,
            longitude: updatedStore.longitude,
            store_note: updatedStore.store_note,
            available_time: updatedStore.available_time
          }
        })
      );
      setStoreDialogOpen(false);
      setEditingStore(null);
    } catch (error) {
      console.error(error);
    }
  }

  const handleDeleteStoreClick = (store) => {
    setDeletedStore(store);
    setDeleteStoreOpen(true);
  };

  const handleDeleteStoreCancel = () => {
    setDeleteStoreOpen(false);
    setDeletedStore(null);
  };

  const handleDeleteStoreOk = async (store) => {
    try {
      await axios.delete("/secure/store/" + store.store_id);
      setStores(stores.filter(item => item.store_id !== store.store_id));
      setDeleteStoreOpen(false);
      setDeletedStore(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleStoreExpand = async (store) => {
    try {
      const { data } = await axios.get("/secure/store/" + store.store_id);
      const storeIndex = stores.findIndex(item => item.store_id === store.store_id);
      setStores(
        stores.map((item, index) => {
          if (index !== storeIndex) {
            return item
          }
          return {
            ...item,
            ...data
          }
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  let storeDialog = null;
  if (storeDialogOpen) {
    storeDialog = <StoreDialog store={editingStore} open={storeDialogOpen} onClose={handleStoreDialogCancel} onAddStore={handleAddStoreOk} onUpdateStore={handleUpdateStoreOk} convertStrToAvailableTimesPerDay={convertStrToAvailableTimesPerDay} />;
  }

  let deleteStoreDialog = null;
  if (deleteStoreOpen) {
    deleteStoreDialog = <DeleteStoreDialog store={deletedStore} open={deleteStoreOpen} onClose={handleDeleteStoreCancel} onOk={handleDeleteStoreOk} />;
  }

  const handleStoreClick = (store) => {
    history.push('/stores/' + store.store_id);
  };

  if (isFetching || status === 'loading') {
    return <CircularProgress />
  }

  return (
    <div className={classes.root}>
      <div className={classes.flexContainer}>
        <Badge badgeContent={stores?.length} max={999} color="primary">
          <StoreIcon fontSize="large" variant="outlined" />
        </Badge>
        <Button color="primary" variant="contained" onClick={handleAddStoreClick}>
          Add store
        </Button>
      </div>
      <div className={classes.content}>
        <Grid container spacing={3}>
          {stores.map(store => (
            <Grid item key={store.store_id} lg={4} md={6} xs={12}>
              <StoreCard
                store={store}
                onClick={handleStoreClick}
                onStoreEdit={handleEditStoreClick}
                onStoreDelete={handleDeleteStoreClick}
                onStoreExpand={handleStoreExpand}
                convertStrToAvailableTimesPerDay={convertStrToAvailableTimesPerDay}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      {deleteStoreDialog}
      {storeDialog}
    </div>
  );
};

export default withHttpErrorHandler(StoreCardList);