import validate from 'validate.js';
import moment from 'moment';

// Before using it we must add the parse and format functions
// Here is a sample implementation using moment.js
validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value, options) {
    return +moment.utc(value).local();
  },
  // Input is a unix timestamp
  format: function (value, options) {
    const format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
    return moment.utc(value).local().format(format);
  }
});

export default {
  user_name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 40
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 100
    }
  },
  confirm_password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 100
    },
    equality: "password"
  },
  user_addr: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },
  user_phone: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 9,
      maximum: 13
    },
    format: {
      pattern: "^[()+-\\s0-9]{9,13}",
      message: "^Must be a valid phone number"
    }
  },
  user_alter_phone: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      minimum: 9,
      maximum: 13
    },
    format: {
      pattern: "^[()+-\\s0-9]{9,13}",
      message: "^Must be a valid phone number"
    }
  },
  gender: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  birthday: {
    presence: { allowEmpty: false, message: 'is required' },
    datetime: {
      dateOnly: true,
      earliest: moment().subtract(100, 'years'),
      latest: moment().add(1, 'days')
    }
  }
};