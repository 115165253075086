export default {
  paper: {
    margin: '12px'
  },
  paperFullWidth: {
    width: 'calc(100% - 24px)'
  },
  paperScrollPaper: {
    maxHeight: 'calc(100% - 24px)'
  }
};