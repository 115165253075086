import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import validate from 'validate.js';
import schema from '../../common/userValidation';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, Button, TextField, Link, FormHelperText, Typography, Container,
  FormControl, InputLabel, Select, MenuItem, Backdrop, CircularProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useMutation } from "react-query";
import axios from 'axios';

const createUser = async (newUser) => {
  await axios.post("/register", newUser);
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  title: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const SignUp = props => {
  const classes = useStyles();

  const [mutate, { status, error }] = useMutation(createUser);

  const [formState, setFormState] = useState({
    isValid: false,
    values: { 'birthday': null },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(
      produce(draft => {
        draft.isValid = errors ? false : true;
        draft.errors = errors || {};
      })
    );
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(
      produce(draft => {
        draft.values[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        draft.touched[event.target.name] = true;
      })
    );
  };

  const handleBirthdayChange = date => {
    setFormState(
      produce(draft => {
        draft.values['birthday'] = date ? date.format("YYYY-MM-DD") : null;
        draft.touched['birthday'] = true;
      })
    );
  }

  const handleSignUp = async (event) => {
    event.preventDefault();
    const newUser = {
      user_name: formState.values.user_name,
      email: formState.values.email,
      password: formState.values.password,
      user_addr: formState.values.user_addr,
      user_phone: formState.values.user_phone,
      user_alter_phone: formState.values.user_alter_phone,
      gender: formState.values.gender,
      birthday: formState.values.birthday
    };
    await mutate(newUser);
  };

  const hasValidationError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  if (status === 'success') {
    return (
      <div className={classes.root}>
        <Container maxWidth="sm">
          <Alert severity="success" variant="filled">Sign up successfully. Please wait for the administrator to activate your account.</Alert>
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <form onSubmit={handleSignUp}>
          <Grid container spacing={2} className={classes.content} justify="center">
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h2">
                Sign up
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('user_name')}
                required
                fullWidth
                inputProps={{ maxLength: schema.user_name.length.maximum }}
                helperText={
                  hasValidationError('user_name') ? formState.errors.user_name[0] : null
                }
                label="Name"
                name="user_name"
                onChange={handleChange}
                type="text"
                autoComplete="name"
                autoFocus={true}
                value={formState.values.user_name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('email')}
                required
                fullWidth
                inputProps={{ maxLength: schema.email.length.maximum }}
                helperText={
                  hasValidationError('email') ? formState.errors.email[0] : null
                }
                label="Email Address"
                name="email"
                onChange={handleChange}
                type="text"
                autoComplete="email"
                value={formState.values.email || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('password')}
                required
                fullWidth
                inputProps={{ maxLength: schema.password.length.maximum }}
                helperText={
                  hasValidationError('password') ? formState.errors.password[0] : null
                }
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                autoComplete="new-password"
                value={formState.values.password || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('confirm_password')}
                required
                fullWidth
                inputProps={{ maxLength: schema.confirm_password.length.maximum }}
                helperText={
                  hasValidationError('confirm_password') ? formState.errors.confirm_password[0] : null
                }
                label="Confirm Password"
                name="confirm_password"
                onChange={handleChange}
                type="password"
                value={formState.values.confirm_password || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('user_addr')}
                required
                fullWidth
                inputProps={{ maxLength: schema.user_addr.length.maximum }}
                helperText={
                  hasValidationError('user_addr') ? formState.errors.user_addr[0] : null
                }
                label="Address"
                name="user_addr"
                onChange={handleChange}
                type="text"
                value={formState.values.user_addr || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                error={hasValidationError('user_phone')}
                required
                fullWidth
                inputProps={{ maxLength: schema.user_phone.length.maximum }}
                helperText={
                  hasValidationError('user_phone') ? formState.errors.user_phone[0] : null
                }
                label="Phone"
                name="user_phone"
                onChange={handleChange}
                type="text"
                autoComplete="tel"
                value={formState.values.user_phone || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                error={hasValidationError('user_alter_phone')}
                required
                fullWidth
                inputProps={{ maxLength: schema.user_alter_phone.length.maximum }}
                helperText={
                  hasValidationError('user_alter_phone') ? formState.errors.user_alter_phone[0] : null
                }
                label="Alternative Phone"
                name="user_alter_phone"
                onChange={handleChange}
                type="text"
                autoComplete="tel"
                value={formState.values.user_alter_phone || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <FormControl required variant="outlined" fullWidth error={hasValidationError('gender')} autoComplete="off">
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select labelId="gender-label"
                  id="gender"
                  label="Gender"
                  name="gender"
                  onChange={handleChange}
                  type="select"
                  value={formState.values.gender || ''}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"m"}>Male</MenuItem>
                  <MenuItem value={"f"}>Female</MenuItem>
                  <MenuItem value={"o"}>Others</MenuItem>
                </Select>
                <FormHelperText>{hasValidationError('gender') ? formState.errors.gender[0] : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  required
                  variant="dialog"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: "start" }}
                  format="YYYY-MM-DD"
                  id="date-picker-inline"
                  label="Birthday"
                  autoComplete="bday"
                  value={formState.values.birthday}
                  minDate={moment().subtract(100, 'years')}
                  maxDate={moment()}
                  error={hasValidationError('birthday')}
                  onChange={handleBirthdayChange}
                  helperText={hasValidationError('birthday') ? formState.errors.birthday[0] : null}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign up
              </Button>
            </Grid>
            {error && <Grid item xs={12}><Alert severity="error" variant="filled">{error.message}</Alert></Grid>}
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="body1">
                Have an account?{' '}
                <Link component={RouterLink} to="/signin" variant="h6">
                  Sign in
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Backdrop className={classes.backdrop} open={status === 'loading'}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default SignUp;