import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  UserList as UserListView,
  StoreList as StoreListView,
  StoreCardList as StoreCardListView,
  Store as StoreView,
  Account as AccountView,
  SignUp as SignUpView,
  SignIn as SignInView,
  Logout as LogoutView
} from './views';

const Routes = (props) => {
  if (!props.isAuthenticated) {
    return (
      <Switch>
        <Redirect exact from="/" to="/signin" />
        <RouteWithLayout exact component={SignUpView} layout={MinimalLayout} path="/signup" />
        <RouteWithLayout exact component={SignInView} layout={MinimalLayout} path="/signin" />
        <Redirect to="/" />
      </Switch>
    );
  }

  if (props.isAdmin) {
    // Admin
    return (
      <Switch>
        <Redirect exact from="/" to="/allusers" />
        <RouteWithLayout exact component={UserListView} layout={MainLayout} path="/allusers" />
        <RouteWithLayout exact component={StoreListView} layout={MainLayout} path="/allstores" />
        <RouteWithLayout exact component={StoreCardListView} layout={MainLayout} path="/stores" />
        <RouteWithLayout exact component={StoreView} layout={MainLayout} path="/stores/:storeId" />
        <RouteWithLayout exact component={AccountView} layout={MainLayout} path="/account" />
        <RouteWithLayout exact component={LogoutView} layout={MinimalLayout} path="/logout" />
        <Redirect to="/" />
      </Switch>
    );
  } else {
    // Supplier
    return (
      <Switch>
        <Redirect exact from="/" to="/stores" />
        <RouteWithLayout exact component={StoreCardListView} layout={MainLayout} path="/stores" />
        <RouteWithLayout exact component={StoreView} layout={MainLayout} path="/stores/:storeId" />
        <RouteWithLayout exact component={AccountView} layout={MainLayout} path="/account" />
        <RouteWithLayout exact component={LogoutView} layout={MinimalLayout} path="/logout" />
        <Redirect to="/" />
      </Switch>
    );
  }
};

export default Routes;