import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import useInterceptors from '../../hooks/useInterceptors';

const withHttpErrorHandler = (WrappedComponent) => {
  return props => {
    const [errorMsg, clearError] = useInterceptors();

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      clearError();
    };

    return (
      <>
        <Snackbar open={Boolean(errorMsg)} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <MuiAlert elevation={6} variant="filled" onClose={handleClose} severity="error">
            {errorMsg}
          </MuiAlert>
        </Snackbar>
        <WrappedComponent {...props} />
      </>
    );
  };
};

export default withHttpErrorHandler;
