import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { StoresTable } from './components';
import { useQuery } from "react-query";
import axios from 'axios';
import {withHttpErrorHandler} from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const getStores = async () => {
  const { data } = await axios.get(
    "/secure/stores"
  );
  return data;
};

const StoreList = () => {
  const classes = useStyles();

  const [stores, setStores] = useState([]);
  const { status, data, isFetching } = useQuery("stores", getStores, { retry: false, refetchOnWindowFocus: false });

  useEffect(() => {
    if (data !== undefined) {
      setStores(data);
    }
  }, [data]);

  if (isFetching || status === 'loading') {
    return <CircularProgress />
  }

  return (
    <div className={classes.root}>
      <div>
        <StoresTable stores={stores} />
      </div>
    </div>
  );
};

export default withHttpErrorHandler(StoreList);