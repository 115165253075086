import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import {
  AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline,
  Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn
} from '@material-ui/icons'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const StoresTable = props => {
  const { stores } = props;

  return (
    <MaterialTable
      icons={tableIcons}
      columns={[
        { title: "Store ID", field: "store_id", render: rowData => <a href={'stores/' + rowData.store_id}>{rowData.store_id}</a> },
        { title: "Store Name", field: "store_name" },
        { title: "Address", field: "address" },
        { title: "Store Phone", field: "store_phone" },
        { title: "Owner ID", field: "owner_id", render: rowData => <a href={'stores?owner=' + rowData.owner_id}>{rowData.owner_id}</a> },
        { title: "Owner Name", field: "owner_name" },
        { title: "Status", field: "is_activate", lookup: { 't': 'Activated', 'f': 'Deactivated' } }
      ]}
      data={stores}
      options={{
        showTitle: false,
        filtering: true,
        searchFieldAlignment: "left",
        pageSize: 10,
        pageSizeOptions: [10, 20, 30],
        emptyRowsWhenPaging: false
      }}
      localization={{
        toolbar: {
          searchPlaceholder: "Search Store"
        }
      }}
    />
  );
};

StoresTable.propTypes = {
  stores: PropTypes.array.isRequired
};

export default StoresTable;
