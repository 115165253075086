import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const userName = useSelector(state => state.auth.userName);
  const roleName = useSelector(state => state.auth.roleName);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography className={classes.name} variant="h4">
        {userName}
      </Typography>
      <Typography variant="body2">{roleName}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;