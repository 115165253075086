import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import theme from './theme';
import './assets/scss/index.scss';
import validators from './common/validators';
import * as actions from './store/actions/index';
import axios from 'axios';

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = (props) => {
  const dispatch = useDispatch();
  dispatch(actions.authAutoSignin());
  const isAuthenticated = useSelector(state => state.auth.accessToken !== null);
  const isAdmin = useSelector(state => state.auth.roleId === '01');
  const refreshUrl = '/refresh';

  // axios interceptors for refreshing access token
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const originalRequest = error.config;

      // Logout if refresh token failed
      if (originalRequest.url === refreshUrl) {
        dispatch(actions.logout());
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        //Logout if refreshToken does not exist
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
          dispatch(actions.logout());
          return Promise.reject(error);
        }

        // Change Authorization header as refreshToken to call refresh API
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + refreshToken;
        return axios.post(refreshUrl)
          .then(response => {
            if (response.status === 200) {
              //Put token to LocalStorage
              const now = Date.now();
              const accessExpirationDate = new Date(now + response.data.access_token_expires_sec * 1000);
              const refreshExpirationDate = new Date(now + response.data.refresh_token_expires_sec * 1000);

              localStorage.setItem('accessToken', response.data.access_token);
              localStorage.setItem('refreshToken', response.data.refresh_token);
              localStorage.setItem('accessExpirationDate', accessExpirationDate);
              localStorage.setItem('refreshExpirationDate', refreshExpirationDate);

              //Change Authorization header
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;

              dispatch(actions.authRefreshToken(response.data.access_token, response.data.refresh_token, accessExpirationDate, refreshExpirationDate));

              // Replace the expired token and retry
              originalRequest.headers['Authorization'] = 'Bearer ' + response.data.access_token;
              return axios(originalRequest);
            }
          })
      }
      return Promise.reject(error);
    }
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes isAuthenticated={isAuthenticated} isAdmin={isAdmin} />
      </Router>
    </ThemeProvider>
  );
}

export default App;