import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Typography, Badge, Grid, Backdrop, Dialog } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { ProductCard, DeleteProductDialog, ProductDialog } from './components';
import { DropzoneDialog } from 'material-ui-dropzone';
import { storeSettings } from '../../common/settings';
import { useQuery } from "react-query";
import axios from 'axios';
import { withHttpErrorHandler } from '../../components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  flexContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between'
  },
  dropzone: {
    minHeight: '150px'
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: '#fff',
  },
  fullSizeImagePaper: {
    display: 'grid',
    maxWidth: '100%',
    maxHeight: '100%',
    margin: 0,
    borderRadius: 'unset'
  }
}));

const getStore = async (key, storeId) => {
  const { data } = await axios.get("/secure/store/" + storeId);
  return data;
};

const getProducts = async (key, storeId) => {
  const { data } = await axios.get("/secure/store/" + storeId + "/product");
  return data;
};

const Store = () => {
  const classes = useStyles();
  const { storeId } = useParams();

  const [store, setStore] = useState(null);
  const [deleteProductOpen, setDeleteProductOpen] = useState(false);
  const [deletedProduct, setDeletedProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [uploadImgDialogOpen, setUploadImgDialogOpen] = useState(false);
  const [imgUploading, setImgUploading] = useState(false);
  const [fullSizeImgUrl, setFullSizeImgUrl] = useState(null);
  const { data: storeData } = useQuery(['store', storeId], getStore, { retry: false, refetchOnWindowFocus: false });
  const { status: fetchingProductsStatus, data: productsData, isFetching: isFetchingProducts, refetch: refetchProducts } = useQuery(
    ['products', storeId], getProducts, { retry: false, refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (storeData !== undefined) {
      setStore(storeData);
    }
  }, [storeData]);

  useEffect(() => {
    if (productsData !== undefined) {
      setProducts(productsData);
    }
  }, [productsData]);

  const handleAddProductClick = () => {
    setEditingProduct(null);
    setProductDialogOpen(true);
  };

  const handleAddProductOk = async (product) => {
    const newProduct = {
      ...product,
      store_id: storeId,
      stock: parseInt(product.stock),
      unit_price: parseInt(product.unit_price)
    };

    try {
      await axios.post("secure/product", newProduct);
      setProductDialogOpen(false);
      await refetchProducts();
    } catch (error) {
      console.error(error);
    }
  };

  const handleProductDialogCancel = () => {
    setProductDialogOpen(false);
    setEditingProduct(null);
  };

  const handleEditProductClick = (product) => {
    try {
      setEditingProduct(product);
      setProductDialogOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateProductOk = async (product) => {
    const updatedProduct = {
      ...product,
      store_id: storeId,
      stock: parseInt(product.stock),
      unit_price: parseInt(product.unit_price),
      product_note: product.product_note ? product.product_note : ''
    };
    //Cannot provide product_img and category_name to the update product API
    delete updatedProduct.product_img;
    delete updatedProduct.category_name;

    try {
      await axios.put("secure/product", updatedProduct);
      const productIndex = products.findIndex(item => item.product_id === product.product_id);
      setProducts(
        products.map((item, index) => {
          if (index !== productIndex) {
            return item;
          }
          return {
            ...item,
            ...updatedProduct,
            category_name: product.category_name
          }
        })
      );
      setProductDialogOpen(false);
      setEditingProduct(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteProductClick = (product) => {
    setDeletedProduct(product);
    setDeleteProductOpen(true);
  };

  const handleDeleteProductCancel = () => {
    setDeleteProductOpen(false);
    setDeletedProduct(null);
  };

  const handleDeleteProductOk = async (product) => {
    try {
      await axios.delete('/secure/product/' + product.product_id);
      setProducts(products.filter(item => item.product_id !== product.product_id));
      setDeleteProductOpen(false);
      setDeletedProduct(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageUploadClick = (product) => {
    setEditingProduct(product);
    setUploadImgDialogOpen(true);
  };

  const handleSubmitImage = async (files) => {
    try {
      const formData = new FormData();
      formData.append('image', files[0]);
      setImgUploading(true);
      const { data } = await axios.post('/secure/product/photo/' + editingProduct.product_id,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      const productIndex = products.findIndex(item => item.product_id === editingProduct.product_id);
      setProducts(
        products.map((item, index) => {
          if (index !== productIndex) {
            return item;
          }
          return {
            ...item,
            product_img: data.product_img // update image url
          }
        })
      );
      setUploadImgDialogOpen(false);
      setEditingProduct(null);
    } catch (error) {
      console.error(error);
    } finally {
      setImgUploading(false);
    }
  }

  const handleImageClick = (imgUrl) => {
    setFullSizeImgUrl(imgUrl);
  };

  if (isFetchingProducts || fetchingProductsStatus === 'loading') {
    return <CircularProgress />
  }

  let productDialog = null;
  if (productDialogOpen) {
    productDialog = <ProductDialog product={editingProduct} open={productDialogOpen} onClose={handleProductDialogCancel} onAddProduct={handleAddProductOk} onUpdateProduct={handleUpdateProductOk} />;
  }

  let deleteProductDialog = null;
  if (deleteProductOpen) {
    deleteProductDialog = <DeleteProductDialog product={deletedProduct} open={deleteProductOpen} onClose={handleDeleteProductCancel} onOk={handleDeleteProductOk} />;
  }

  let uploadImgDialog = null;
  if (uploadImgDialogOpen) {
    uploadImgDialog = (
      <DropzoneDialog
        acceptedFiles={['image/jpeg']}
        filesLimit={1}
        dialogTitle="Upload Product Image (Max. 5 MB)"
        dropzoneText="Click or Drag and Drop a file here"
        cancelButtonText={"Cancel"}
        submitButtonText={"Submit"}
        previewText="Preview:"
        maxFileSize={5000000} //5 MB
        open={uploadImgDialogOpen}
        onClose={() => { setUploadImgDialogOpen(false); setEditingProduct(null); }}
        onSave={handleSubmitImage}
        useChipsForPreview={true}
        dropzoneClass={classes.dropzone}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom>
        {store?.store_name}
      </Typography>
      <div className={classes.flexContainer}>
        <Badge badgeContent={products?.length} max={999} color="primary">
          <ListAltIcon fontSize="large" variant="outlined" />
        </Badge>
        {
          products.length < storeSettings.maxProductCount
            ?
            (< Button color="primary" variant="contained" onClick={handleAddProductClick}>
              Add Product
            </Button>)
            : null
        }
      </div>
      <div className={classes.content}>
        <Grid container spacing={3}>
          {products.map(product => (
            <Grid item key={product.product_id} xs={12} sm={6} md={4} lg={4} xl={3}>
              <ProductCard
                product={product}
                onProductEdit={handleEditProductClick}
                onProductDelete={handleDeleteProductClick}
                onImageUpload={handleImageUploadClick}
                onImageClick={handleImageClick}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      {deleteProductDialog}
      {productDialog}
      {uploadImgDialog}
      <Backdrop className={classes.backdrop} open={imgUploading}>
        <CircularProgress />
      </Backdrop>
      <Dialog maxWidth={false} classes={{ paper: classes.fullSizeImagePaper }} open={Boolean(fullSizeImgUrl)} onClose={() => setFullSizeImgUrl(null)}>
        <img src={fullSizeImgUrl} onClick={() => setFullSizeImgUrl(null)} alt="" />
      </Dialog>
    </div>
  );
};

export default withHttpErrorHandler(Store);