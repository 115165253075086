import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import {
  AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline,
  Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn
} from '@material-ui/icons'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const ProductsTable = props => {
  const { products, onProductUpdate, onProductDelete } = props;

  return (
    <MaterialTable
      icons={tableIcons}
      columns={[
        { title: "Id", field: "product_id", hidden: true },
        { title: "Name", field: "product_name" },
        { title: "Stock", field: "stock", type: 'numeric' },
        { title: "Price", field: "unit_price", type: 'numeric' }
      ]}
      data={products}
      options={{
        showTitle: false,
        filtering: false,
        paging: false,
        searchFieldAlignment: "left",
        padding: "dense"
      }}
      localization={{
        toolbar: {
          searchPlaceholder: "Search Product"
        }
      }}
      editable={{
        onRowUpdate: async (newData, oldData) => { await onProductUpdate(newData); },
        onRowDelete: async (rowData) => { await onProductDelete(rowData); }
      }}
    />
  );
};

ProductsTable.propTypes = {
  products: PropTypes.array.isRequired
};

export default ProductsTable;