import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText,
} from '@material-ui/core';

const DeleteStoreDialog = props => {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Delete Store</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure to delete <b>{props.store.store_name}</b>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { props.onOk(props.store); }} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteStoreDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  store: PropTypes.object.isRequired
};

export default DeleteStoreDialog;