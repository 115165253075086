import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import schema from '../../../../common/productValidation';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, InputLabel, Select, MenuItem, FormHelperText
} from '@material-ui/core';

const productCategories = [
  { id: 1, name: "Aquatic Product" },
  { id: 2, name: "Flower Product" },
  { id: 3, name: "Medical Product" },
  { id: 4, name: "Productos Textiles" },
  { id: 5, name: "Pesque y Pague" }
]

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
}));

const ProductDialog = props => {
  const { open, product, onClose, onAddProduct, onUpdateProduct } = props;

  const classes = useStyles();

  const [isCreateProduct] = useState(!product);
  const [neverTouched, setNeverTouched] = useState(true);

  const [formState, setFormState] = useState({
    isValid: false,
    values: isCreateProduct ? {} : product,
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(
      produce(draft => {
        draft.isValid = errors ? false : true;
        draft.errors = errors || {};
      })
    );
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(
      produce(draft => {
        draft.values[event.target.name] = event.target.value;
        draft.touched[event.target.name] = true;
      })
    );
    setNeverTouched(false);
  };

  let hasValidationError = null;
  if (isCreateProduct) {
    hasValidationError = field => formState.touched[field] && formState.errors[field] ? true : false;
  } else {
    hasValidationError = field => formState.errors[field] ? true : false;
  }

  return (
    <Dialog open={open} onClose={onClose} disableBackdropClick={true} fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">{isCreateProduct ? 'Create Product' : 'Edit Product'}</DialogTitle>
      <DialogContent>
        <form autoComplete="off">
          <Grid container spacing={2} className={classes.content}>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('product_name')}
                required
                fullWidth
                inputProps={{ maxLength: schema.product_name.length.maximum }}
                helperText={
                  hasValidationError('product_name') ? formState.errors.product_name[0] : null
                }
                label="Product Name"
                name="product_name"
                onChange={handleChange}
                type="text"
                value={formState.values.product_name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl required variant="outlined" fullWidth error={hasValidationError('category_id')}>
                <InputLabel id="product-category-label">Category</InputLabel>
                <Select labelId="product-category-label"
                  id="category_id"
                  name="category_id"
                  label="Category"
                  onChange={handleChange}
                  type="select"
                  value={formState.values.category_id || ''}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {
                    productCategories.map(item => {
                      return (
                        <MenuItem key={`product-category-${item.id}`} value={item.id}>{item.name}</MenuItem>
                      );
                    })
                  }
                </Select>
                <FormHelperText>{hasValidationError('category_id') ? formState.errors.category_id[0] : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                error={hasValidationError('stock')}
                required
                fullWidth
                inputProps={{ maxLength: 10 }}
                helperText={
                  hasValidationError('stock') ? formState.errors.stock[0] : null
                }
                placeholder="10"
                label="Stock"
                name="stock"
                onChange={handleChange}
                type="text"
                value={formState.values.stock}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                error={hasValidationError('unit_price')}
                required
                fullWidth
                inputProps={{ maxLength: 10 }}
                helperText={
                  hasValidationError('unit_price') ? formState.errors.unit_price[0] : null
                }
                placeholder="100"
                label="Unit Price"
                name="unit_price"
                onChange={handleChange}
                type="text"
                value={formState.values.unit_price}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('product_note')}
                fullWidth
                inputProps={{ maxLength: schema.product_note.length.maximum }}
                helperText={
                  hasValidationError('product_note') ? formState.errors.product_note[0] : null
                }
                multiline={true}
                rows={3}
                rowsMax={3}
                label="Note"
                name="product_note"
                onChange={handleChange}
                type="text"
                value={formState.values.product_note || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            isCreateProduct ? onAddProduct(formState.values)
              : onUpdateProduct(
                { ...formState.values, category_name: productCategories.find(x => x.id === formState.values.category_id).name }
              );
          }}
          color="primary"
          variant="contained"
          disabled={neverTouched || !formState.isValid}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ProductDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  product: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onAddProduct: PropTypes.func.isRequired,
  onUpdateProduct: PropTypes.func.isRequired
};

export default ProductDialog;