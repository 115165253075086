import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card, CardActionArea, CardHeader, CardContent, CardActions, Tooltip,
  Divider, IconButton, Collapse, List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import { StoreCardDetail } from './components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LocationOn as LocationOnIcon, Phone as PhoneIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {},
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  listItemIcon: {
    minWidth: '42px'
  }
}));

const StoreCard = props => {
  const { className, store, onClick, onStoreEdit, onStoreDelete, onStoreExpand, convertStrToAvailableTimesPerDay, ...rest } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    if (!expanded) {
      onStoreExpand(store);
    }
    setExpanded(!expanded);
  };

  let storeCardDetail = null;
  if (expanded) {
    storeCardDetail = <CardContent><StoreCardDetail store={store} convertStrToAvailableTimesPerDay={convertStrToAvailableTimesPerDay} /></CardContent>;
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardActionArea onClick={() => { onClick(store); }}>
        <CardHeader
          title={store.store_name}
        />
      </CardActionArea>
      <CardContent>
        <List component="div" dense={true} disablePadding={true}>
          <ListItem dense disableGutters>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <LocationOnIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={store.address} />
          </ListItem>
          <ListItem dense disableGutters>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <PhoneIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={store.store_phone} />
          </ListItem>
        </List>
      </CardContent>
      <Divider />
      <CardActions disableSpacing>
        <Tooltip title="Edit">
          <IconButton onClick={async () => { await onStoreEdit(store); }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={async () => { await onStoreDelete(store); }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {storeCardDetail}
      </Collapse>
    </Card>
  );
};

StoreCard.propTypes = {
  className: PropTypes.string,
  store: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onStoreEdit: PropTypes.func.isRequired,
  onStoreDelete: PropTypes.func.isRequired,
  onStoreExpand: PropTypes.func.isRequired,
  convertStrToAvailableTimesPerDay: PropTypes.func.isRequired
};

export default StoreCard;