import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card, CardHeader, CardActionArea, CardMedia, CardContent, CardActions, Tooltip,
  IconButton, List, ListItem, ListItemIcon, ListItemText, Typography
} from '@material-ui/core';
import {
  AttachMoney as AttachMoneyIcon, ShoppingCart as ShoppingCartIcon, Edit as EditIcon, Delete as DeleteIcon,
  Note as NoteIcon, Image as ImageIcon, Category as CategoryIcon
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {},
  media: {
    // height: 0,
    // paddingTop: '56.25%', // 16:9
    height: '160px',
    backgroundSize: 'contain'
  },
  listItemIcon: {
    minWidth: '42px'
  },
  note: {
    width: '100%',
    overflow: 'auto',
    '& > pre': {
      whiteSpace: 'pre-wrap'
    }
  }
}));

const ProductCard = props => {
  const { className, product, onProductEdit, onProductDelete, onImageUpload, onImageClick, ...rest } = props;
  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title={product.product_name} />
      <CardActionArea onClick={() => product.product_img ? onImageClick(product.product_img) : null}>
        <CardMedia
          className={classes.media}
          image={product.product_img ? product.product_img : ''}
        />
      </CardActionArea>
      <CardContent>
        <List component="div" dense={true} disablePadding={true}>
          <ListItem dense disableGutters>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <ShoppingCartIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={product.stock} />
          </ListItem>
          <ListItem dense disableGutters>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <AttachMoneyIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={product.unit_price} />
          </ListItem>
          <ListItem dense disableGutters>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <CategoryIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={product.category_name} />
          </ListItem>
          <ListItem dense disableGutters>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <NoteIcon color="secondary" />
            </ListItemIcon>
            <Typography variant="body2" component="div" className={classes.note}>
              <pre>{product.product_note}</pre>
            </Typography>
          </ListItem>
        </List>
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title="Edit">
          <IconButton onClick={() => { onProductEdit(product); }}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Upload Product Image">
          <IconButton onClick={() => { onImageUpload(product); }}>
            <ImageIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={() => { onProductDelete(product); }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
  onProductEdit: PropTypes.func.isRequired,
  onProductDelete: PropTypes.func.isRequired
};

export default ProductCard;