import * as actionTypes from '../actions/actionTypes';
import produce from 'immer';

const initialState = {
  accessToken: null,
  refreshToken: null,
  accessExpirationDate: null,
  refreshExpirationDate: null,
  userId: null,
  userName: null,
  roleId: null,
  roleName: null,
  error: null,
  loading: false,
  authRedirectPath: '/'
};

const authStart = (state, action) => {
  return produce(state, draftState => {
    draftState.error = null;
    draftState.loading = true;
  })
};

const authSuccess = (state, action) => {
  return produce(state, draftState => {
    draftState.accessToken = action.accessToken;
    draftState.refreshToken = action.refreshToken;
    draftState.accessExpirationDate = action.accessExpirationDate;
    draftState.refreshExpirationDate = action.refreshExpirationDate;
    draftState.userId = action.userId;
    draftState.userName = action.userName;
    draftState.roleId = action.roleId;
    draftState.roleName = action.roleName;
    draftState.error = null;
    draftState.loading = false;
  })
};

const authFail = (state, action) => {
  return produce(state, draftState => {
    draftState.error = action.error;
    draftState.loading = false;
  })
};

const authLogout = (state, action) => {
  return produce(state, draftState => {
    draftState.accessToken = null;
    draftState.refreshToken = null;
    draftState.accessExpirationDate = null;
    draftState.refreshExpirationDate = null;
    draftState.userId = null;
    draftState.userName = null;
    draftState.roleId = null;
    draftState.roleName = null;
  })
};

const authRefreshToken = (state, action) => {
  return produce(state, draftState => {
    draftState.accessToken = action.accessToken;
    draftState.refreshToken = action.refreshToken;
    draftState.accessExpirationDate = action.accessExpirationDate;
    draftState.refreshExpirationDate = action.refreshExpirationDate;
  })
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.AUTH_REFRESH_TOKEN: return authRefreshToken(state, action);
    default:
      return state;
  }
};

export default reducer;