import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  List, ListItem, ListItemIcon, ListSubheader, Typography, Chip, CircularProgress
} from '@material-ui/core';
import {
  Note as NoteIcon, AccessTime as AccessTimeIcon,
  Check as CheckIcon, Close as CloseIcon
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  listItemIconRoot: {
    minWidth: '42px'
  },
  alignItemsFlexStart: {
    marginTop: '0px'
  },
  availableDay: {
    lineHeight: '24px',
    fontSize: '0.85rem'
  },
  note: {
    width: '100%',
    overflow: 'auto',
    '& > pre': {
      whiteSpace: 'pre-wrap'
    }
  },
  dayChip: {
    marginRight: '12px'
  },
  map: {
    width: '100%',
    '& > iframe': {
      width: '100%',
      height: '250px',
      border: 0
    }
  }
}));

const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
const times = ['Mañana', 'Tarde', 'Noche'];

const StoreCardDetail = props => {
  const { store, convertStrToAvailableTimesPerDay } = props;
  const classes = useStyles();

  if (!store.available_time) {
    return <CircularProgress />
  }

  const availableTimesPerDay = convertStrToAvailableTimesPerDay(store.available_time);

  return (
    <List component="div" dense={true} disablePadding={true}>
      {
        process.env.REACT_APP_GOOGLE_MAP_API_KEY ?
          <ListItem dense disableGutters alignItems="flex-start">
            <div className={classes.map}>
              <iframe
                title="map"
                frameBorder="0"
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&q=${store.latitude},${store.longitude}`}
              />
            </div>
          </ListItem>
          : null
      }
      <ListItem dense disableGutters alignItems="flex-start">
        <ListItemIcon classes={{ root: classes.listItemIconRoot, alignItemsFlexStart: classes.alignItemsFlexStart }}>
          <AccessTimeIcon color="secondary" />
        </ListItemIcon>
        <List component="div" dense={true} disablePadding={true}>
          {[...days.keys()].map(dayIndex => {
            return (
              <ul key={`availableDay-${dayIndex}`}>
                <ListSubheader disableGutters disableSticky classes={{ root: classes.availableDay }}>{days[dayIndex]}</ListSubheader>
                <ListItem dense disableGutters>
                  {[...times.keys()].map(timeIndex => {
                    return (
                      <Chip
                        className={classes.dayChip}
                        key={`availableTimes-${dayIndex}-${timeIndex}`}
                        variant="outlined"
                        size="small"
                        label={times[timeIndex]}
                        icon={availableTimesPerDay[dayIndex][timeIndex] ? <CheckIcon /> : <CloseIcon />}
                        color={availableTimesPerDay[dayIndex][timeIndex] ? "secondary" : "default"}
                      />
                    );
                  })}
                </ListItem>
              </ul>
            );
          })}
        </List>
      </ListItem>
      <ListItem dense disableGutters alignItems="flex-start">
        <ListItemIcon classes={{ root: classes.listItemIconRoot, alignItemsFlexStart: classes.alignItemsFlexStart }}>
          <NoteIcon color="secondary" />
        </ListItemIcon>
        <Typography variant="body2" component="div" className={classes.note}>
          <pre>{store.store_note}</pre>
        </Typography>
      </ListItem>
    </List>
  );
}

StoreCardDetail.propTypes = {
  store: PropTypes.object.isRequired,
  convertStrToAvailableTimesPerDay: PropTypes.func.isRequired
};

export default StoreCardDetail;