export default {
  product_name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 60
    }
  },
  stock: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: true,
      strict: true,
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 2147483647
    }
  },
  unit_price: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      onlyInteger: true,
      strict: true,
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 2147483647
    }
  },
  product_note: {
    length: {
      maximum: 200
    }
  },
  category_id: {
    presence: { allowEmpty: false, message: '^Category is required' }
  }
};