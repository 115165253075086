import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import schema from '../../../../common/userValidation';
import produce from 'immer';
import {
  Card, CardHeader, CardContent, CardActions,
  Grid, Divider, Button, TextField
} from '@material-ui/core';

var schemaWhitelist = {
  password: true,
  confirm_password: true
};

const Password = props => {
  const { onUpdatePassword } = props;

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      password: null,
      confirm_password: null
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, validate.cleanAttributes(schema, schemaWhitelist));
    setFormState(
      produce(draft => {
        draft.isValid = errors ? false : true;
        draft.errors = errors || {};
      })
    );
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(
      produce(draft => {
        draft.values[event.target.name] = event.target.value;
        draft.touched[event.target.name] = true;
      })
    );
  };

  const hasValidationError = field => formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Card>
      <form autoComplete="off">
        <CardHeader title="Password" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                inputProps={{ maxLength: schema.password.length.maximum }}
                value={formState.values.password || ''}
                variant="outlined"
                error={hasValidationError('password')}
                helperText={
                  hasValidationError('password') ? formState.errors.password[0] : null
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                name="confirm_password"
                onChange={handleChange}
                type="password"
                inputProps={{ maxLength: schema.confirm_password.length.maximum }}
                value={formState.values.confirm_password || ''}
                variant="outlined"
                error={hasValidationError('confirm_password')}
                helperText={
                  hasValidationError('confirm_password') ? formState.errors.confirm_password[0] : null
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button color="primary" variant="outlined" disabled={!formState.isValid} onClick={() => { onUpdatePassword(formState.values); }}>
            Update Password
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  onUpdatePassword: PropTypes.func.isRequired
};

export default Password;