import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import schema from '../../../../common/userValidation';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import {
  Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, FormHelperText, InputLabel, Select, MenuItem
} from '@material-ui/core';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
}));

var schemaWhitelist = {
  user_name: true,
  user_addr: true,
  user_phone: true,
  user_alter_phone: true,
  gender: true,
  birthday: true
};

const EditUserDialog = props => {
  const classes = useStyles();

  const [neverTouched, setNeverTouched] = useState(true);
  const [formState, setFormState] = useState({
    isValid: false,
    values: props.user,
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, validate.cleanAttributes(schema, schemaWhitelist));
    setFormState(
      produce(draft => {
        draft.isValid = errors ? false : true;
        draft.errors = errors || {};
      })
    );
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(
      produce(draft => {
        draft.values[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        draft.touched[event.target.name] = true;
      })
    );
    setNeverTouched(false);
  };

  const handleBirthdayChange = date => {
    setFormState(
      produce(draft => {
        draft.values['birthday'] = date ? date.format("YYYY-MM-DD") : null;
        draft.touched['birthday'] = true;
      })
    );
    setNeverTouched(false);
  }

  const hasValidationError = field => formState.errors[field] ? true : false;

  return (
    <Dialog open={props.open} onClose={props.onClose} disableBackdropClick={true} fullWidth={true} maxWidth="sm">
      <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2} className={classes.content}>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('user_name')}
                required
                fullWidth
                inputProps={{ maxLength: schema.user_name.length.maximum }}
                helperText={
                  hasValidationError('user_name') ? formState.errors.user_name[0] : null
                }
                label="Name"
                name="user_name"
                onChange={handleChange}
                type="text"
                value={formState.values.user_name || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                inputProps={{ readOnly: true }}
                label="Email Address (Read Only)"
                name="email"
                type="text"
                value={formState.values.email || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={hasValidationError('user_addr')}
                required
                fullWidth
                inputProps={{ maxLength: schema.user_addr.length.maximum }}
                helperText={
                  hasValidationError('user_addr') ? formState.errors.user_addr[0] : null
                }
                label="Address"
                name="user_addr"
                onChange={handleChange}
                type="text"
                value={formState.values.user_addr || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                error={hasValidationError('user_phone')}
                required
                fullWidth
                inputProps={{ maxLength: schema.user_phone.length.maximum }}
                helperText={
                  hasValidationError('user_phone') ? formState.errors.user_phone[0] : null
                }
                label="Phone"
                name="user_phone"
                onChange={handleChange}
                type="text"
                value={formState.values.user_phone || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                error={hasValidationError('user_alter_phone')}
                required
                fullWidth
                inputProps={{ maxLength: schema.user_alter_phone.length.maximum }}
                helperText={
                  hasValidationError('user_alter_phone') ? formState.errors.user_alter_phone[0] : null
                }
                label="Alternative Phone"
                name="user_alter_phone"
                onChange={handleChange}
                type="text"
                value={formState.values.user_alter_phone || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <FormControl required variant="outlined" fullWidth error={hasValidationError('gender')}>
                <InputLabel id="gender-label">Gender</InputLabel>
                <Select labelId="gender-label"
                  id="gender"
                  label="Gender"
                  name="gender"
                  onChange={handleChange}
                  type="select"
                  value={formState.values.gender || ''}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"m"}>Male</MenuItem>
                  <MenuItem value={"f"}>Female</MenuItem>
                  <MenuItem value={"o"}>Others</MenuItem>
                </Select>
                <FormHelperText>{hasValidationError('gender') ? formState.errors.gender[0] : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={6} xs={12}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  autoOk
                  fullWidth
                  required
                  variant="dialog"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: "start" }}
                  format="YYYY-MM-DD"
                  id="date-picker-inline"
                  label="Birthday"
                  autoComplete="bday"
                  value={formState.values.birthday}
                  minDate={moment().subtract(100, 'years')}
                  maxDate={moment()}
                  error={hasValidationError('birthday')}
                  onChange={handleBirthdayChange}
                  helperText={hasValidationError('birthday') ? formState.errors.birthday[0] : null}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { props.onOk(formState.values); }} color="primary" variant="contained" disabled={neverTouched || !formState.isValid}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

EditUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired
};

export default EditUserDialog;