export default {
  store_name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 60
    }
  },
  store_phone: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 15
    },
    format: {
      pattern: "^[()+-\\s0-9]{1,15}",
      message: "^Must be a valid phone number"
    }
  },
  store_note: {
    length: {
      maximum: 200
    }
  },
  codigo_departamento: {
    presence: { allowEmpty: false, message: '^Departamento is required' }
  },
  codigo_distrito: {
    presence: { allowEmpty: false, message: '^Distrito is required' }
  },
  codigo_barrio: {
    presence: { allowEmpty: false, message: '^Barrio is required' }
  },
  address: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 200
    }
  },
  latitude: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 12
    },
    numericality: {
      onlyInteger: false,
      strict: true,
      greaterThanOrEqualTo: -90,
      lessThanOrEqualTo: 90
    }
  },
  longitude: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 12
    },
    numericality: {
      onlyInteger: false,
      strict: true,
      greaterThanOrEqualTo: -180,
      lessThanOrEqualTo: 180
    }
  }
};