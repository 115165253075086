import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from "material-table";
import IconButton from '@material-ui/core/IconButton';
import {
  AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline,
  Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn,
  CheckBoxOutlined, CheckBoxOutlineBlankOutlined, LockOpen
} from '@material-ui/icons'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CheckBoxOutlined: forwardRef((props, ref) => <CheckBoxOutlined {...props} ref={ref} />),
  CheckBoxOutlineBlankOutlined: forwardRef((props, ref) => <CheckBoxOutlineBlankOutlined {...props} ref={ref} />),
  LockOpen: forwardRef((props, ref) => <LockOpen {...props} ref={ref} />)
};

const UsersTable = props => {
  const { users, onUserEdit, onUserDelete, onToggleUserActivation, onResetPassword } = props;

  return (
    <MaterialTable
      icons={tableIcons}
      columns={[
        { title: "User ID", field: "user_id", render: rowData => <a href={'stores?owner=' + rowData.user_id}>{rowData.user_id}</a> },
        { title: "Name", field: "user_name" },
        { title: "Email", field: "email" },
        { title: "Role", field: "role_name" },
        {
          title: "Status",
          field: "is_activate",
          lookup: { 't': 'Activated', 'f': 'Deactivated' },
          cellStyle: { textAlign: 'center' },
          render: rowData => rowData.is_activate === 't'
            ? <IconButton color="primary" size="small" onClick={() => onToggleUserActivation(rowData)}><CheckBoxOutlined /></IconButton>
            : <IconButton size="small" onClick={() => onToggleUserActivation(rowData)}><CheckBoxOutlineBlankOutlined /></IconButton>
        }
      ]}
      data={users}
      options={{
        showTitle: false,
        filtering: true,
        searchFieldAlignment: "left",
        pageSize: 10,
        pageSizeOptions: [10, 20, 30],
        emptyRowsWhenPaging: false
      }}
      localization={{
        toolbar: {
          searchPlaceholder: "Search User"
        },
        body: {
          deleteTooltip: "Delete User",
          editRow: {
            deleteText: "Are you sure delete this user?"
          }
        }
      }}
      actions={[
        rowData => ({
          icon: Edit,
          tooltip: 'Edit User',
          onClick: (event, rowData) => { onUserEdit(rowData); }
        }),
        rowData => ({
          icon: LockOpen,
          tooltip: 'Reset Password',
          onClick: (event, rowData) => { onResetPassword(rowData); }
        })
      ]}
      editable={{
        onRowDelete: async (rowData) => { await onUserDelete(rowData); }
      }}
    />
  );
};

UsersTable.propTypes = {
  users: PropTypes.array.isRequired,
  onUserEdit: PropTypes.func.isRequired,
  onUserDelete: PropTypes.func.isRequired,
  onToggleUserActivation: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired
};

export default UsersTable;
