import React, { useState, useEffect } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import schema from '../../common/userValidation';
import produce from 'immer';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, TextField, Link, Typography, Container, Backdrop, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as actions from '../../store/actions/index';

var schemaWhitelist = {
  email: true,
  password: true
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  title: {
    marginTop: theme.spacing(2)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const SignIn = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(state => state.auth.accessToken !== null);
  const authLoading = useSelector(state => state.auth.loading);
  const authError = useSelector(state => state.auth.error);
  const authRedirectPath = useSelector(state => state.auth.authRedirectPath);

  const onAuth = (email, password) => dispatch(actions.auth(email, password));

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, validate.cleanAttributes(schema, schemaWhitelist));
    setFormState(
      produce(draft => {
        draft.isValid = errors ? false : true;
        draft.errors = errors || {};
      })
    );
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(
      produce(draft => {
        draft.values[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        draft.touched[event.target.name] = true;
      })
    );
  };

  const handleSignIn = event => {
    event.preventDefault();
    onAuth(formState.values.email, formState.values.password);
  };

  if (isAuthenticated) {
    return <Redirect to={authRedirectPath} />;
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        {authError && <Alert severity="error" variant="filled">{authError}</Alert>}
        <form className={classes.form} onSubmit={handleSignIn}>
          <Grid container spacing={2} className={classes.content} justify="center">
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h2">
                Sign in
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                error={hasError('email')}
                fullWidth
                inputProps={{ maxLength: schema.email.length.maximum }}
                helperText={
                  hasError('email') ? formState.errors.email[0] : null
                }
                label="Email"
                name="email"
                onChange={handleChange}
                type="text"
                autoComplete="email"
                autoFocus={true}
                value={formState.values.email || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                error={hasError('password')}
                fullWidth
                inputProps={{ maxLength: schema.password.length.maximum }}
                helperText={
                  hasError('password') ? formState.errors.password[0] : null
                }
                label="Password"
                name="password"
                onChange={handleChange}
                type="password"
                autoComplete="current-password"
                value={formState.values.password || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.signInButton}
                color="primary"
                disabled={!formState.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign in
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary" variant="body1">
                Don't have an account?{' '}
                <Link component={RouterLink} to="/signup" variant="h6">
                  Sign up
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info">
                If you forget the password, please contact the administrator.
              </Alert>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Backdrop className={classes.backdrop} open={authLoading}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default SignIn;